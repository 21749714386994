import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { ITranslations } from './interfaces/config';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: 'de',
    interpolation: {
      escapeValue: false,
    },
  });

export const applyTranslations = (translations: ITranslations, defaultLng: string) => {
  const adminToolTranslations = translations['fishven-admin-tool'];

  if (adminToolTranslations) {
    const resources = {
      en: { translation: adminToolTranslations.en },
      de: { translation: adminToolTranslations.de },
      fr: { translation: adminToolTranslations.fr },
      it: { translation: adminToolTranslations.it },
    };

    i18n.use(initReactI18next).init({
      resources,
      lng: defaultLng,
      interpolation: {
        escapeValue: false,
      },
    });
  }
};

export default i18n;
